@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

.nav {
  padding-left: 1rem;
  padding-right: 1rem;
}

.nav +.nav .item a {
  color: map-get($neutrals, 'grey-900');

  &:hover, &:focus {
    color: map-get($neutrals, 'grey-700');
  }
}

.list {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
}
.item,
.item a {
  color: map-get($neutrals, 'white');
  cursor: pointer;
  text-decoration: none;

  &:hover, &:focus {
    color: map-get($colours, 'secondary');
  }
}

.horizontal .list{
  display: flex;
}

.horizontal .item {
  margin-left: 1rem;

  &:first-child {
    margin-left: unset;
  }
}

.footer__menu {
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
}

@include screen-up(md) {
  .footer__menu {
    margin-left: auto;
    margin-right: 7rem;
  }
}

.footer__menu li a {
  position: relative;
  top: -2px;
  font-size: map-get($font-sizes, "sm");
}

