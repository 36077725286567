@use "variables" as *;

@mixin screen-up($break) {
  @media screen and (min-width: map-get($breakpoints, '#{$break}')) {
    @content;
  }
}

/*
   Get the value from the breakpoint invoked. Amend the value in a
   variable before it is referenced in the media query.

   Pass in the amended value instead
 */
@mixin screen-down($break) {
  $value: map-get($breakpoints, $break);
  $updated: calc($value - 0.0001rem);

  @media screen and (max-width: $updated ) {
    @content;
  }
}

/*
   Check whether the background is light or dark and apply
   a colour which will contrast enough to be seen
 */
@mixin conditional-colour($colour) {
  @if lightness($colour) < 60% {
    color: map-get($colours, 'white');
  }
  @else {
    color: map-get($colours, 'black');
  }
}


@mixin button($colour, $outline: 'true') {
  @if $colour == 'transparent' {
    @warn 'Cannot compare colours with a transparent variable';
  }

  @include conditional-colour($colour);
  background-color: $colour;

  &:hover {
    @include conditional-colour($colour);
    background-color: lighten($colour, 15%);
  }

  &:focus {
    @include conditional-colour(lighten($colour, 10%));
    background-color: lighten($colour, 10%);
    outline: .25em solid rgba(lighten($colour, 10%), 0.5);
  }
}

@mixin link($colour) {
  @if $colour == 'transparent' {
    @warn 'Cannot work with a transparent variable';
  }

  color: $colour;

  &:hover {
    color: lighten($colour, 15%);
  }

  &:focus {
    color: lighten($colour, 10%);
  }
}