@use 'theme/base/_variables.scss' as *;
@use 'theme/base/_mixins.scss' as *;

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  word-break: break-word;
  word-wrap: break-word;
}

html {
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

@media screen and (min-width: map-get($breakpoints, 'lg')) {
  html {
    scroll-padding-top: 7rem;
  }
}

body, html, #root {
  background-color: map-get($colours, 'background');
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  line-height: map-get($line-heights, 'normal');
}

body {
  font-family: map-get($fonts, 'primary');
}

.no-scroll, .no-scrolling {
  height: 100%; overflow: hidden;
  transform: unset;
}

@include screen-up(lg) {
  .no-scroll {
    height: unset; overflow: visible
  }
}

.App{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: map-get($colours, 'primary');
  border-radius: 100px;
}

.page {
  min-height: 100vh;
}

.section {
  padding-top: clamp(3rem, 10vh, 5rem);
  padding-bottom: clamp(3rem, 10vh, 5rem);
}

svg {
  width:  auto;
  height: auto;
  max-width: 100%;
}

.sr-only {
  visibility: hidden;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 0;
}
