@use "../../theme/base/variables.scss" as *;
@use "../../theme/base/mixins.scss" as *;

.btn,
.button {
  display: inline-block;
  padding: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  text-align: center;
  text-decoration: none;
  text-wrap: none;
  white-space: nowrap;
  cursor: pointer;
}

@include screen-up(sm) {
  .btn,
  .button {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.button,
.btn {
  & + .btn,
  & + .button {
    margin-left: 1rem;
  }
}
.button_sm,
.button_small,
.btn_small {
  padding: .75rem 1rem;
}

.active {
  color: map-get($colours, "secondary");
}

.button_link {
  padding: unset;
}

@each $name, $colour in $colours {
  .btn_#{$name} {
    background-color: $colour;

    // Check lightness of the color
    // Check lightness of the color

    @if lightness($colour) < 30% {
      // If the color is Dark, set the text color to light
      color: lighten($colour, 70%);
      border-color: lighten($colour, 70%);

    } @else if lightness($colour) < 60% {
      // If the color is dark, set the text color to light
      color: darken($colour, 70%);

    } @else if lightness($colour) < 90% {
      // If the color is very light, set the text color to dark
      color: darken($colour, 80%);

    } @else {
      color: darken($colour, 80%);
    }

    // Hover styles
    &:hover,
    &:focus {
      background-color: lighten($colour, 10%);
    }
  }

  .btn_link_#{$name} {
    color: $colour;
    text-decoration: none;
    border: 0;
    background-color: transparent;

    // Hover styles
    &:hover,
    &:focus {
      color: lighten($colour, 10%);
      text-decoration: underline;
    }
  }
}
