@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

.cookie {
  position:fixed;
  z-index: 99;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  align-items: center;
  background-color: rgba(map-get($neutrals, 'grey-700'), .9);
  max-height: 20rem;
  transition: all ease-in .3s;
}

@include screen-up(lg) {
  .cookie {
    display: flex;
  }
}

.cookie__content {
  color: map-get($neutrals, 'white' );
}

.cookie__title {
  font-size: map-get($font-sizes, 'lg');
  color: map-get($neutrals, 'white');
  margin-bottom: .5rem;
}

.cookie__summary {
  color: map-get($neutrals, 'grey-200');
  margin: 0;
}

.cookie__link {
  color: map-get($colours, 'secondary');
}

.cookie__actions {
  margin-top: 2rem;
  margin-left: auto;
}

@include screen-up(lg) {
  .cookie__actions {
    display: flex;
    margin-top: unset;
    margin-left: 2rem;
  }
}

@include screen-up(xl) {
  .cookie__actions {
    margin-left: auto;
  }
}

.cookie_hidden {
  max-height: 0;
  overflow: hidden;
  transition: all ease-out .3s;
}