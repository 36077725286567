
$breakpoints: (
  '2xs':  20rem,       // 320px
  'xs':   30rem,       // 480px
  'sm':   35rem,       // 560px
  'md':   48rem,       // 768px
  'lg':   64rem,       // 1024px
  'xl':   80rem,       // 1200px
  '2xl':  90rem,       // 1440px
  '3xl':  112.5rem,    // 1800px
  '80ch': 80ch
);

/*** 1.1 - Columns ***/

$column_count: 12;

/*** 2.0 - Colours ***/

$colours: (
  'primary':    #9341ff,
  'secondary':  #ee117f,
  'accent':     #c4b3e0,
  'text':       #f6f7fe,
  'background': #040a1a
);

$neutrals: (
  'white':    #ffffff,
  'grey-100': #e5e8ed,
  'grey-200': #ccd2dc,
  'grey-300': #9ba6ba,
  'grey-400': #6c7d98,
  'grey-500': #415577,
  'grey-600': #2c4267,
  'grey-700': #182f57,
  'grey-800': #13274b,
  'grey-900': #071227
);

$alerts: (
  'success': #6cff67,
  'error': #ffb1b1,
  'warning': #e2cc9a,
  'info': #a0eaff
);


/*** 3.0 - Fonts ***/

$font-sizes: (
  'h1': clamp(3rem, 10vw, 4.5rem),
  'h2': clamp(2.625rem, 10vw, 4rem),
  'h3': clamp(1.65rem, 10vw, 2.5rem),
  'h4': clamp(1.5rem, 10vw, 2.0625rem),
  'h5': clamp(1.15rem, 10vw, 1.625rem),
  'h6': clamp(.875rem, 10vw, 1.25rem),
  'lg': clamp(1.125rem, 10vw, 1.25rem),
  'normal': 1rem,
  'sm': clamp(.75rem, 10vw, .875rem)
);

$fonts: (
  'primary': 'Montserrat'
);

$line-heights: (
  'none': 1,
  'tight': 1.2,
  'normal': 1.65,
  'loose': 2
);

$spacing: (
  'xs': .375,
  'sm': .75,
  'normal': 1,
  'lg': 1.25,
  'xl': 1.5,
);