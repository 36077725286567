@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;


.button {
  position: absolute;
  bottom: 1rem;
  right: 0;
  width: 3rem;
  height: 3rem;
  border: 0;
  border-radius: .25rem;
  background-color: map-get($neutrals, 'grey-800');
  cursor: pointer;

  &:hover {
  background-color: map-get($neutrals, 'grey-700');

  }
}

.icon {
  color: map-get($neutrals, 'white');
}