@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

.copyright {
  display: flex;
  margin-bottom: 1rem;
  display: block;
  text-align: center;
  color: map-get($neutrals, "grey-400");
  font-size: map-get($font-sizes, "sm");
}

@include screen-up(lg) {
  .copyright {
    flex-grow: 1;
    margin-top: 1rem;
    text-align: left;
  }
}

.copyright__link {
  color: map-get($neutrals, "grey-400");
  text-decoration: none;

  &:hover,
  &:focus {
    color: map-get($colours, "secondary");
  }
}

.copyright__year,
.copyright__company {
  display: block;
}
.copyright__separator {
  display: none;
}

@include screen-up(md) {
  .copyright__separator {
    display: inline-block;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .copyright__year,
  .copyright__company {
    display: inline-block;
  }
}