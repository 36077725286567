@use "../../theme/base/_variables.scss" as *;
@use "../../theme/base/_mixins.scss" as *;

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: map-get($breakpoints, "xl");
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
}

@include screen-up(lg) {
  .footer {
    flex-direction: row-reverse;
  }
}
